import { Add as AddIcon, CloseOutlined as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Pagination, Snackbar, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { createProject, deleteProject, getAllProjects, getImage, updateProject } from '../../api/api';
import DragDropImageUpload from '../../components/DragUpload';

const ProjectDashboard = () => {
    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

    const projectsPerPage = 6;

    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        try {
            const response = await getAllProjects();
            setProjects(response);
        } catch (error) {
            console.error('Error fetching projects:', error);
            showSnackbar('Error fetching projects', 'error');
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleOpenDialog = (project = null) => {
        setCurrentProject(project || { name: '', description: '', image: '' });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setCurrentProject(null);
        setOpenDialog(false);
    };

    const handleSaveProject = async () => {
        try {
            if (currentProject.id) {
                // Update existing project
                await updateProject(currentProject);
                showSnackbar('Project updated successfully', 'success');
            } else {
                await createProject(currentProject);
                showSnackbar('Project created successfully', 'success');
            }
            handleCloseDialog();
            fetchProjects();
        } catch (error) {
            console.error('Error saving project:', error);
            showSnackbar('Error saving project', 'error');
        }
    };

    const showSnackbar = (message, severity) => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleDelete = async (id) => {
        try {
            await deleteProject(id);
            showSnackbar('Project deleted successfully', 'success');
            fetchProjects();
        } catch (error) {
            console.error('Error deleting project:', error);
            showSnackbar('Error deleting project', 'error');
        }
    };

    const handleUploadSuccess = useCallback((imageId) => {
        setCurrentProject(prevProject => ({
          ...prevProject,
          image: imageId
        }));
      }, []);
    
      const handleUploadError = useCallback((error) => {
        console.error("Erreur lors du téléchargement de l'image:", error);
        // You can add more error handling here, such as displaying an error message to the user
        showSnackbar('Error uploading image', 'error');
      }, []);

    const indexOfLastProject = page * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold">Project Dashboard</h1>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpenDialog()}
                >
                    Create Project
                </Button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {currentProjects.map((project) => (
                    <div key={project.id} className="bg-white rounded-lg shadow-md overflow-hidden relative">
                        <IconButton onClick={() => handleDelete(project.id)} aria-label="delete" className='absolute t-0 l-0 bg-red-500  rounded-full'>
                            <CloseIcon />
                        </IconButton>
                        <img
                            src={getImage(project.image) || 'https://placehold.co/300x200'}
                            alt={project.name}
                            className="w-full h-48 object-cover"
                        />
                        <div className="p-4">
                            <h3 className="text-xl font-semibold mb-2">{project.name}</h3>
                            <p className="text-gray-600 mb-4 overflow-hidden">{project.description}</p>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={() => handleOpenDialog(project)}
                            >
                                Modify
                            </Button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mt-8 flex justify-center">
                <Pagination
                    count={Math.ceil(projects.length / projectsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </div>

            <Dialog 
            open={openDialog} 
            onClose={handleCloseDialog}
            maxWidth="md"
            fullWidth
            PaperProps={{
                className: 'rounded-lg shadow-xl'
            }}
            >
            <DialogTitle className="bg-gray-50 border-b border-gray-200 flex justify-between items-center p-4">
                <h2 className="text-2xl font-semibold text-gray-800">
                {currentProject && currentProject.id ? 'Modify Project' : 'Create Project'}
                </h2>
                <IconButton onClick={handleCloseDialog} size="small" className="text-gray-500 hover:text-gray-700">
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="p-6">
                <div className="grid grid-cols-2 gap-6">
                <div className="space-y-4">
                    <TextField
                    autoFocus
                    label="Project Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={currentProject ? currentProject.name : ''}
                    onChange={(e) => setCurrentProject({ ...currentProject, name: e.target.value })}
                    className="bg-white my-8"
                    />
                    <TextField
                    label="Description"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={currentProject ? currentProject.description : ''}
                    onChange={(e) => setCurrentProject({ ...currentProject, description: e.target.value })}
                    className="bg-white"
                    />
                    <TextField
                    autoFocus
                    label="Website URL"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={currentProject ? currentProject.website : ''}
                    onChange={(e) => setCurrentProject({ ...currentProject, website: e.target.value })}
                    className="bg-white my-8"
                    />
                </div>
                <div className="flex flex-col items-center justify-center bg-gray-50 rounded-lg p-4">
                    <h3 className="text-lg font-medium text-gray-700 mb-2">Project Image</h3>
                    <DragDropImageUpload
                    onUploadSuccess={handleUploadSuccess} 
                    onUploadError={handleUploadError}
                    initialImageUrl={
                        currentProject && currentProject.image ? getImage(currentProject.image) : null
                    }
                    />
                </div>
                </div>
            </DialogContent>
            <DialogActions className="bg-gray-50 border-t border-gray-200 p-4">
                <Button 
                onClick={handleCloseDialog} 
                className="text-gray-600 hover:text-gray-800"
                >
                Cancel
                </Button>
                <Button 
                onClick={handleSaveProject} 
                variant="contained" 
                className="bg-blue-500 hover:bg-blue-600 text-white"
                >
                Save Project
                </Button>
            </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </div>
    );
};

export default ProjectDashboard;