import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Footer from './components/footer';
import Header from './components/header';
import AdminDashboard from './pages/admin';
import Login from './pages/admin/login';

function App() {
    return (
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header isAdmin={true} />
          <main className="flex-grow">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/*" element={<AdminDashboard />} />
            </Routes>
          </main>
          <Footer/>
        </div>
      </Router>
    );
  }

export default App;