import React from 'react';

function AdminDashboard() {
  return (
    <div className="bg-white shadow-md rounded p-4">
      <h2 className="text-xl mb-2">Quick Stats</h2>
      <ul className="list-disc pl-5">
        <li>Total Users: 1,234</li>
        <li>Active Products: 567</li>
        <li>Pending Orders: 89</li>
      </ul>
    </div>
  );
}

export default AdminDashboard;