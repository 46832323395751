import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
console.log(API_URL); // Should print: https://api.zaidou-dataworks.com/api


const api = axios.create({
    baseURL: API_URL,
    });

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = localStorage.getItem('refreshToken');
                const response = await axios.post(`${API_URL}/users/refresh`, { refreshToken });

                const { token } = response.data;
                localStorage.setItem('token', token);

                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                return api(originalRequest);
            } catch (refreshError) {
                await new Promise(resolve => setTimeout(resolve, 3000));
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('user');
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }

        if (!error.response) {
            return Promise.reject(new Error('Network error. Please check your internet connection.'));
        }

        return Promise.reject(error);
    }
);

export const login = async (firstname, password) => {
    try {
        const response = await api.post('/users/login', { firstname, password });
        const { token, refreshToken, user } = response.data;

        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
        return Promise.reject(error);
    }
}

export const verifyToken = async () => {
    try {
        const response = await api.post('/users/verify');
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
}

export const getImage = (id) => 
    id ? `${API_URL}/images/${id}` : null;

export const uploadImage = async (imageFile) => {
    try {
    const formData = new FormData();
    formData.append('image', imageFile);
  
    const response = await api.post('/images/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    
    });
    return { status: response.status, data: response.data };
  
  } catch (error) {
    return { status: error.response.status, data: error.response.data };
  }}


export const getAllProjects = async () => {
    try {
        const response = await api.get('/projects');
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
}

export const updateProject = async (project) => {
    try {
        const response = await api.put(`/projects/${project.id}`, project);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
}

export const createProject = async (project) => {
    try {
        const response = await api.post('/projects', project);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
}

export const deleteProject = async (projectId) => {
    try {
        const response = await api.delete(`/projects/${projectId}`);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
}

export const getAllReviews = async () => {
    try {
      const response = await api.get('/reviews');
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  
  export const createReview = async (review) => {
    try {
      const response = await api.post('/reviews', review);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  
  export const updateReview = async (reviewId, review) => {
    try {
      const response = await api.put(`/reviews/${reviewId}`, review);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  
  export const deleteReview = async (reviewId) => {
    try {
      const response = await api.delete(`/reviews/${reviewId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

