import { AppBar, Box, Button, Toolbar } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/image/logo.png';

const Header = () => {
    const navigate = useNavigate();

    return (
        <AppBar position="static" className={`p-2 md:p-4 bg-gradient-to-br from-purple-500 to-blue-700'shadow-none`}>
            <Toolbar>
                    <img 
                        src={logo} 
                        alt="Logo" 
                        className='w-10 h-auto md:w-20 md:h-auto cursor-pointer'
                        onClick={() => navigate('/')}
                    />
                <Box className="ml-auto flex justify-end">
                        <>
                            <Button color="inherit" component="a" href="https://zaidou-dataworks.com">FrontPage</Button>
                        </>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;