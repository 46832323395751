import { Box, Button, Container, TextField, Typography } from '@mui/material';
import React from 'react';
import { login } from '../../api/api';



const Login = () => {

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const username = data.get('username');
        const password = data.get('password');
    
        await login(username, password).then(() => {
            window.location.href = '/';
        }).catch((error) => {
            console.error('Error logging in:', error);
        })
    }

    return (
        <Container maxWidth="sm" className="mt-10 p-8 rounded-lg">
            <Box className="mb-5">
                <Typography variant="h4" component="h1" gutterBottom className="text-center mb-8">
                    Login
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box className="mb-4">
                        <TextField
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            variant="outlined"
                            className="mb-4"
                        />
                    </Box>
                    <Box className="mb-4">
                        <TextField
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            variant="outlined"
                            className="mb-4"
                        />
                    </Box>
                    <Button type="submit" variant="contained" color="primary" fullWidth className="bg-blue-500 hover:bg-blue-700 text-white">
                        Login
                    </Button>
                </form>
            </Box>
        </Container>
    );
}

export default Login;