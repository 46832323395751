import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button, FormControl, IconButton, InputLabel, MenuItem, Modal,
  Rating, Select, TextField, Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { createReview, deleteReview, getAllProjects, getAllReviews, getImage, updateReview } from '../../api/api';
import DragDropImageUpload from '../../components/DragUpload';

const AdminReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingReview, setEditingReview] = useState(null);
  const [formData, setFormData] = useState({
    firstname: '', lastname: '', email: '', jobName: '', rating: '', comment: '', projectId: '', image: ''
  });
  const [sortBy, setSortBy] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchReviews();
    fetchProjects();
  }, []);

  const fetchReviews = async () => {
    setLoading(true);
    try {
      const data = await getAllReviews();
      setReviews(data);
    } catch (error) {
      console.error('Failed to fetch reviews:', error);
    }
    setLoading(false);
  };

  const fetchProjects = async () => {
    try {
      const data = await getAllProjects();
      setProjects(data);
    } catch (error) {
      console.error('Failed to fetch projects:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this review?')) {
      try {
        await deleteReview(id);
        alert('Review deleted successfully');
        fetchReviews();
      } catch (error) {
        console.error('Failed to delete review:', error);
      }
    }
  };

  const handleEdit = (review) => {
    setEditingReview(review);
    setFormData(review);
    setModalOpen(true);
  };

  const handleCreate = () => {
    setEditingReview(null);
    setFormData({firstname: '', lastname: '', email: '', jobName: '', rating: '', comment: '', projectId: '', image: ''});
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingReview) {
        await updateReview(editingReview.id, formData);
        alert('Review updated successfully');
      } else {
        await createReview(formData);
        alert('Review created successfully');
      }
      setModalOpen(false);
      fetchReviews();
    } catch (error) {
      console.error('Failed to save review:', error);
    }
  };

  const handleUploadSuccess = useCallback((imageId) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      image: imageId
    }));
  }, []);

  const handleUploadError = useCallback((error) => {
    console.error("Error uploading image:", error);
    alert('Error uploading image');
  }, []);

  const filteredReviews = reviews.filter(review => 
    sortBy === 'all' || review.projectId === sortBy
  );

  const filteredProjects = projects.filter(project =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Manage Reviews</h1>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleCreate}
        >
          Create New Review
        </Button>
      </div>

      <Box className="mb-4">
        <FormControl fullWidth>
          <InputLabel>Sort by Project</InputLabel>
          <Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <MenuItem value="all">All Reviews</MenuItem>
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <div className="space-y-4">
        {filteredReviews.map((review) => (
          <div key={review.id} className="bg-white p-4 rounded-lg shadow">
            <div className="flex items-center mb-2">
              <Avatar src={getImage(review.image)} alt={`${review.firstname} ${review.lastname}`} />
              <div className="ml-3">
                <Typography variant="subtitle1" component="div" className="font-bold">
                  {review.firstname} {review.lastname}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {review.jobName}
                </Typography>
              </div>
            </div>
            <Rating value={parseInt(review.rating)} readOnly />
            <Typography variant="body1" className="mt-2">
              {review.comment}
            </Typography>
            <Typography variant="caption" color="text.secondary" className="mt-2 block">
              Project Name: {review.project?.name || 'N/A'}
            </Typography>
            <div className="mt-4 flex justify-end space-x-2">
              <IconButton size="small" onClick={() => handleEdit(review)}>
                <EditIcon />
              </IconButton>
              <IconButton size="small" onClick={() => handleDelete(review.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '90vh',
          overflowY: 'auto',
        }}>
          <h2 id="modal-title" className="text-xl font-bold mb-4">
            {editingReview ? 'Edit Review' : 'Create Review'}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-4">
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  fullWidth
                  label="Job Name"
                  name="jobName"
                  value={formData.jobName}
                  onChange={handleInputChange}
                  required
                />
                <FormControl fullWidth>
                  <InputLabel>Rating</InputLabel>
                  <Select
                    name="rating"
                    value={formData.rating}
                    onChange={handleInputChange}
                    required
                  >
                    {[1, 2, 3, 4, 5].map((num) => (
                      <MenuItem key={num} value={num}>{num}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!editingReview && (
                  <FormControl fullWidth>
                    <InputLabel>Project</InputLabel>
                    <Select
                      name="projectId"
                      value={formData.projectId}
                      onChange={handleInputChange}
                      required
                    >
                      {filteredProjects.map((project) => (
                        <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <div className="space-y-4">
                <TextField
                  fullWidth
                  label="Comment"
                  name="comment"
                  multiline
                  rows={4}
                  value={formData.comment}
                  onChange={handleInputChange}
                />
                <div className="mt-4">
                  <Typography variant="subtitle1" className="mb-2">Review Image</Typography>
                  <DragDropImageUpload
                    onUploadSuccess={handleUploadSuccess}
                    onUploadError={handleUploadError}
                    initialImageUrl={formData.image ? getImage(formData.image) : null}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end space-x-2 mt-4">
              <Button onClick={handleModalClose}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">
                {editingReview ? 'Update' : 'Create'}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminReviews;