import { Menu as Bars3Icon, Home as HomeIcon, RateReview, Work, Close as XMarkIcon } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';

import { verifyToken } from '../api/api';
import AdminDashboard from './admin/adminDashboard';
import ProjectDashboard from './admin/adminProject';
import AdminUsers from './admin/adminReviews';

function AdminLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon },
    { name: 'Reviews', href: '/reviews', icon: RateReview },
    { name: 'Projects', href: '/projects', icon: Work},
  ];

  useEffect(() => {
    async function verify() {
      try {
        await verifyToken();
      } catch (error) {
        console.error('Failed to verify token:', error);
        window.location.href = '/login';
      }
    }
    verify();
  });

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  const NavLink = ({ item }) => (
    <Link
      to={item.href}
      className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
        isActive(item.href)
          ? 'bg-gray-100'
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
      }`}
    >
      <item.icon
        className={`mr-3 flex-shrink-0 h-6 w-6 ${
          isActive(item.href)
            ? 'text-purple-500'
            : 'text-gray-400 group-hover:text-gray-500'
        }`}
      />
      <span
        className={
          isActive(item.href)
            ? 'bg-gradient-to-br from-purple-500 to-blue-700 text-transparent bg-clip-text'
            : ''
        }
      >
        {item.name}
      </span>
    </Link>
  );

  return (
    <div className="min-h-screen flex overflow-hidden bg-gray-100">
      {/* Sidebar for mobile */}
      <div className={`fixed inset-0 flex z-40 md:hidden ${sidebarOpen ? '' : 'hidden'}`} role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" aria-hidden="true" onClick={() => setSidebarOpen(false)}></div>
        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
          <div className="absolute top-0 right-0 -mr-12 pt-2">
            <button
              type="button"
              className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={() => setSidebarOpen(false)}
            >
              <span className="sr-only">Close sidebar</span>
              <XMarkIcon className="h-6 w-6 text-white" />
            </button>
          </div>
          <div className="mt-5 flex-1 h-0 overflow-y-auto">
            <nav className="px-2 space-y-1">
              {navigation.map((item) => (
                <NavLink key={item.name} item={item} />
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-col h-0 flex-1">
            <div className="flex-1 flex flex-col overflow-y-auto">
              <nav className="flex-1 px-2 py-4 bg-white space-y-1">
                {navigation.map((item) => (
                  <NavLink key={item.name} item={item} />
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex">
              <h1 className="text-2xl font-semibold text-gray-900 my-auto">Admin Dashboard</h1>
            </div>
          </div>
        </div>

        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <Routes>
                <Route index element={<AdminDashboard />} />
                <Route path="/reviews" element={<AdminUsers />} />
                <Route path="/projects" element={<ProjectDashboard />} />
                <Route path="/*" element={<AdminDashboard />} />
              </Routes>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AdminLayout;