// src/components/Footer.jsx
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-indigo-500 py-4 mt-auto">
      <p className="text-center text-white">
        2024 ZAIDOU DATAWORKS
      </p>
      <div className="text-center text-white mt-2">
        <a href="/legal-notice" className="mx-2 hover:underline">Legal Notice</a>
        <a href="/terms-and-conditions" className="mx-2 hover:underline">Terms and Conditions</a>
        <a href="/privacy-policy" className="mx-2 hover:underline">Privacy Policy</a>
      </div>
      <div className="text-center text-white mt-2">
        <a href="https://www.linkedin.com/in/achile-coskun/" className="mx-2 hover:underline">
          <LinkedInIcon size={24} />
        </a>
        <a href="https://github.com/Zaidoudou" className="mx-2 hover:underline">
          <GitHubIcon size={24} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;