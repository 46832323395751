import AddIcon from '@mui/icons-material/Add';
import CloudDone from '@mui/icons-material/CloudDone';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadImage } from '../api/api';

const DragDropImageUpload = ({ onUploadSuccess, onUploadError, initialImageUrl = null }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(initialImageUrl);
  const [imageLoadError, setImageLoadError] = useState(false);

  useEffect(() => {
    setUploadedImage(initialImageUrl);
    setImageLoadError(false);
  }, [initialImageUrl]);

  const handleImageError = () => {
    setImageLoadError(true);
    setUploadedImage(null);
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    setUploadedImage(URL.createObjectURL(file));
    setImageLoadError(false);
    setIsUploading(true);
    setUploadSuccess(false);

    try {
      const result = await uploadImage(file);
      const imageId = result.data;

      if (onUploadSuccess) {
        await onUploadSuccess(imageId);
        setUploadSuccess(true);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      if (onUploadError) onUploadError(error);
      alert('Error uploading the image.');
      setImageLoadError(true);
      setUploadedImage(null);
    } finally {
      setIsUploading(false);
    }
  }, [onUploadSuccess, onUploadError]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  const renderContent = () => {
    if (uploadedImage && !imageLoadError) {
      return (
        <>
          <img 
            src={uploadedImage} 
            alt="Uploaded" 
            style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
            onError={handleImageError}
          />
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
            }}
          >
            <EditIcon />
          </IconButton>
        </>
      );
    } else {
      return <AddIcon sx={{ fontSize: 40, color: 'grey.500' }} />;
    }
  };

  return (
    <Box sx={{ textAlign: 'center', padding: 2 }}>
      <Box
        {...getRootProps()}
        sx={{
          width: 200,
          height: 200,
          backgroundColor: uploadedImage && !imageLoadError ? 'transparent' : 'grey.300',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <input {...getInputProps()} />
        {renderContent()}
      </Box>
      {isUploading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {uploadSuccess && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
          <CloudDone color="success" />
          <Typography variant="body1" color="success.main" sx={{ marginLeft: 1 }}>
            Upload Successful!
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DragDropImageUpload;